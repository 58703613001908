import httpClient from '@/clients/httpClient';
import {
    allArea,
    measureMean, raterGroupAll,
} from '@/constants';
import {
    BffQuestionsFavorableResponse,
    BffQuestionsResponse,
} from '@/interfaces/bff';
import {
    QuestionListParams,
    QuestionListPayload,
    QuestionListAreaPayload,
    QuestionListFiltersPayload, AggregatedQuestionListParams,
} from '@/interfaces/services';
import {
    validateEmpty,
    addFiltersToParams,
} from '@/services/utilities';

/* URLs */
const getOverviewQuestionListUrl = '/api/instances/{instanceId}/question-list';
const getCategoryQuestionListUrl = '/api/instances/{instanceId}/categories/{category}/question-list';
const getAreaQuestionListUrl = '/api/instances/{instanceId}/categories/{category}/area/question-list';
const getAggregatedQuestionListUrl = '/api/instances/{instanceId}/categories/{category}/aggregated/area/question-list';

/*
   * Question list component
   * Used on overview page for question template
   * Used on question list page for other "default" templates
*/
export const getScoredQuestions = async ({
    source,
    instanceId,
    waveId,
    category,
    measure = measureMean, // =mean|percentFavorable
    locale,
    selectedFilterScales,
    area,
    raterGroup,
}: QuestionListParams): Promise<BffQuestionsResponse | BffQuestionsFavorableResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    let params: QuestionListPayload | QuestionListAreaPayload | QuestionListFiltersPayload = {
        waveId,
        locale,
        measure,
    };
    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = getOverviewQuestionListUrl;

    if (category) {
        url = getCategoryQuestionListUrl;
        if (area) {
            url = getAreaQuestionListUrl;

            const areaParams = {
                area: area !== allArea ? area : undefined,
                raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
            };
            params = { ...params, ...areaParams };
        }
        url = url.replace('{category}', category);
    }

    url = url.replace('{instanceId}', instanceId);

    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffQuestionsResponse | BffQuestionsFavorableResponse) => response);
};

/*
   * Question list component (aggregated)
   * Used on question list page for aggregated template
*/
export const getAggregatedScoredQuestions = async ({
    source,
    instanceId,
    guideId,
    category,
    locale,
}: AggregatedQuestionListParams): Promise<BffQuestionsResponse | BffQuestionsFavorableResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Guide-ID', guideId);
    validateEmpty('Category', category);

    const params = {
        guideId,
        locale,
    };

    let url = getAggregatedQuestionListUrl;
    url = url
        .replace('{instanceId}', instanceId)
        .replace('{category}', category);

    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffQuestionsResponse | BffQuestionsFavorableResponse) => response);
};

import getTemplate from '@/services/templateService';

export const getDefaultStateTemplate = () => ({
    template: {
        id: 'dummy',
        type: 'generic',
        name: 'Generic Template',
        configurationGroups: [],
        pages: {
            startPage: {
                components: [
                    {
                        type: 'TemplateCardComponent',
                        id: 'generic1',
                        x: 3,
                        y: 0,
                        w: 6,
                        h: 3,
                        title: 'Loading',
                        teaser: 'Template fetch in progress ...',
                        components: [],
                    },
                ],
            },
        },
    },
});

const templateStore = {
    namespaced: true,
    state: getDefaultStateTemplate(),
    getters: {},
    mutations: {
        resetState(state): void {
            Object.assign(state, getDefaultStateTemplate());
        },
        initializeState(state, loadedTemplate): void {
            state.template = loadedTemplate;
        },
    },
    actions: {
        async get({ dispatch, commit }, { templateId }) {
            return getTemplate(templateId).then((template) => {
                commit('initializeState', template.data);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default templateStore;

import Vue from 'vue';
import { Chrome } from 'vue-color';
import Notifications from 'vue-notification';
import { iframeResizerContentWindow } from 'iframe-resizer';
import VueTour from 'vue-tour';
import CockpitAction from '@/components/CockpitActionCard/CockpitAction.vue';
import Icon from '@/components/global/Icon.vue';
import Tabs from '@/components/global/Tabs.vue';
import GuidedTourLink from '@/components/global/GuidedTourLink.vue';
import FramedSwitch from '@/components/global/FramedSwitch.vue';
import CustomSelect from '@/components/global/CustomSelect.vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18nLocalization from './i18n/i18nLocalization';
import router from './router';
import store from './store';

import 'fontsource-open-sans/latin.css';

// eslint-disable-next-line no-unused-expressions
iframeResizerContentWindow;

Vue.config.productionTip = false;

require('vue-tour/dist/vue-tour.css');

require('material-design-icons-iconfont/dist/material-design-icons.min.css');

require('@mdi/font/css/materialdesignicons.css');

Vue.use(VueTour);
Vue.use(Notifications);

Vue.component('ColorPicker', Chrome);
Vue.component('Icon', Icon);
Vue.component('CockpitAction', CockpitAction);
Vue.component('Tabs', Tabs);
Vue.component('GuidedTourLink', GuidedTourLink);
Vue.component('FramedSwitch', FramedSwitch);
Vue.component('CustomSelect', CustomSelect);

new Vue({
    vuetify,
    router,
    store,
    i18n: i18nLocalization,
    render: (h) => h(App),
}).$mount('#app');

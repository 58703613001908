import { getDescriptiveResultAggregated } from '@/services/resultsService';

export const getDefaultStateTemplate = () => ({
    results: {
        n: 0,
        top_n: 0,
        mid_n: 0,
        low_n: 0,
        distribution: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
        },
        avg: 0,
        std: 0,
        stdSample: 0,
        top_n_percent: 0,
        mid_n_percent: 0,
        low_n_percent: 0,
        '1_n_percent': 0,
        '2_n_percent': 0,
        '3_n_percent': 0,
        '4_n_percent': 0,
        '5_n_percent': 0,
        median: 0,
    },
});

const resultsAggregatedStore = {
    namespaced: true,
    state: getDefaultStateTemplate(),
    getters: {},
    mutations: {
        resetState(state): void {
            Object.assign(state, getDefaultStateTemplate());
        },
        initializeState(state, loadedResults): void {
            state.results = loadedResults;
        },
    },
    actions: {
        async get({ dispatch, commit }, { instanceId, componentId }) {
            return getDescriptiveResultAggregated(instanceId, componentId).then((results) => {
                commit('initializeState', results.data);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default resultsAggregatedStore;

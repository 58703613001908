import httpClient from '@/clients/httpClient';
import { validateEmpty } from '@/services/utilities';

export const getDescriptiveResults = async (instanceId, componentId) => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Component-ID', componentId);
    return httpClient.get(`/api/results/${instanceId}/descriptive/${componentId}`, { params: {} })
        .then((response) => response);
};

export const getDescriptiveResultAggregated = async (instanceId, componentId) => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Component-ID', componentId);
    return httpClient.get(`/api/results/${instanceId}/descriptive/${componentId}/aggregated`, { params: {} })
        .then((response) => response);
};


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SelectInterface } from '@/interfaces/components';
import { OptionItem } from '@/interfaces/visual';

@Component({})
export default class Select extends Vue implements SelectInterface {
    @Prop({ default: [] }) options!: OptionItem[];
    @Prop() value!: string;

    emitInput(newVal: string): void {
        this.$emit('input', newVal);
    }
}

import httpClient from '@/clients/httpClient';
import { validateEmpty } from '@/services/utilities';

/*
 * Generic template loader
 */
const getTemplate = async (templateId) => {
    validateEmpty('Template-ID', templateId);
    return httpClient.get(`/api/template/${templateId}`, { params: {} })
        .then((response) => response);
};

export default getTemplate;
